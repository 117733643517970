import { Drawer, Form } from 'antd';

import styles from './Company.module.css';
import { CheckOutlined } from '@ant-design/icons';
import { PencilIcon } from 'assets/icons';
import arrowRight from 'assets/images/arrow-right.svg';
import CompanyEdit from './CompanyEdit';
import CustomAvatar from 'components/UI/Avatar/Avatar';
import CustomButton from 'components/UI/Button/Button';
import { useSelector } from 'react-redux';
import { useState } from 'react';

const rolesData = [
  'NonQM Lender',
  'Commercial Lender',
  'Alternative Lender',
  'Insurance Company',
  'Hard Money/Private Lender',
];

function CompanyDrawer({
  activeModal,
  onCloseActiveModal,
  company,
  setShouldShowAdminModal,
  updateCompanyData,
  additionalUpdate,
}) {
  const { role } = useSelector((s) => s.user.userData);

  const [isEdit, setIsEdit] = useState(false);
  const [form] = Form.useForm();
  const isPLA = /^superadmin$|pla/i.test(role);

  function CompanyDetailsRow({ title, value }) {
    return (
      <div className={styles.companyDetailsRowContainer}>
        <p className={styles.companyDetailsRowTitle}>{title}</p>
        <p className={styles.companyDetailsRowValue}>{value || 'No info'}</p>
      </div>
    );
  }

  return (
    <Drawer
      placement="right"
      onClose={onCloseActiveModal}
      closeIcon={false}
      bodyStyle={{ padding: 0 }}
      className={styles.drawer}
      width={360}
      visible={activeModal.show}
      headerStyle={{ display: 'none' }}
    >
      <div className={styles.drawerWrapper}>
        <div className={styles.drawerHeader}>
          <img onClick={onCloseActiveModal} src={arrowRight} alt="" />
          <p className={styles.headerTitle}>Company Profile</p>
          <div
            onClick={(e) => {
              e.stopPropagation();
              setIsEdit(true);
            }}
            className={styles.buttonEdit}
          >
            {isPLA &&
              (isEdit ? (
                <div
                  onClick={async (e) => {
                    e.stopPropagation();
                    setIsEdit(false);
                    form.submit();
                  }}
                  className={styles.buttonEditOn}
                >
                  <span className={styles.buttonEditOnTitle}>Save</span>
                  <CheckOutlined size={24} className={styles.buttonEditOnIcon} />
                </div>
              ) : (
                <PencilIcon />
              ))}
          </div>
        </div>
        {isEdit ? (
          <CompanyEdit
            additionalUpdate={additionalUpdate}
            updateCompanyData={updateCompanyData}
            companyData={company?.company_data}
            form={form}
          />
        ) : (
          <div className={styles.containerScroll}>
            <div className={styles.section}>
              <div className={styles.drawerCompanyInfo}>
                <CustomAvatar src={company?.company_data?.logo} size={72} />
                <div className={styles.drawerCompanyDetails}>
                  <p className={styles.companyDetaisTitle}>{company?.company_data?.name}</p>
                  <p className={styles.companyDetaisSubTitle}>Created: {company?.company_data?.account_create_date}</p>
                </div>
              </div>
              {/* <div className={styles.divider} />
              <div className={styles.containerButtons}>
                {profileButtons.map((el) => (
                  <CustomButton
                    className={styles.customBtn}
                    onClick={() => setShouldShowAdminModal(true)}
                    text={el.name}
                    key={el.id}
                  />
                ))}
              </div> */}
              <div className={styles.divider} />
              <div className={styles.rowFlex}>
                <CompanyDetailsRow title="Account Owner" value={company.company_data?.account_owner} />
                <CompanyDetailsRow title="Referred by:" value={company.company_data?.referral_name} />
              </div>
            </div>
            <div className={styles.section}>
              <p className={styles.titleUpperCase}>company admin:</p>
              <div className={styles.drawerCompanyInfo}>
                <CustomAvatar
                  src={company?.company_data?.admin?.profile_photo}
                  fullName={company?.company_data?.admin?.full_name}
                  size={56}
                />
                <div className={styles.drawerCompanyDetails}>
                  <p className={styles.companyAdminTitle}>{company?.company_data?.admin?.full_name}</p>
                  <CustomButton
                    className={styles.customBtn}
                    onClick={() => setShouldShowAdminModal(true)}
                    text="Update Admin"
                  />
                </div>
              </div>
              <div className={styles.divider} />
              <div className={styles.companyAdminInfo}>
                <p className={styles.companyDetailsRowValue}>{company?.company_data?.admin?.phone}</p>
                <p className={styles.companyDetailsRowValue}>{company?.company_data?.admin?.email}</p>
              </div>
            </div>
            <div className={styles.section}>
              <p className={styles.titleUpperCase}>company details:</p>
              <CompanyDetailsRow
                title="Company role:"
                value={
                  company?.company_data?.type
                    ? rolesData.includes(company?.company_data?.type)
                      ? 'Fulfillment'
                      : 'Originator'
                    : 'No Info'
                }
              />
              <div className={styles.divider} />
              <CompanyDetailsRow title="Company type:" value={company?.company_data?.type} />
              <div className={styles.divider} />
              <CompanyDetailsRow title="Company website:" value={company?.company_data?.website} />
              <div className={styles.divider} />
              <CompanyDetailsRow
                title="Location:"
                value={
                  <p className={styles.locationInfo}>
                    <p>{company?.company_data?.address_street1}</p>
                    <p>{company?.company_data?.address_street2}</p>
                    <span>
                      {company?.company_data?.city} <span>•</span> {company?.company_data?.state?.name} <span>•</span>{' '}
                      {company?.company_data?.zip}
                    </span>
                  </p>
                }
              />
            </div>
          </div>
        )}
      </div>
    </Drawer>
  );
}

export default CompanyDrawer;
